import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { FullPage, TextWithCheckIcon } from "../../components";
import { STUDNERM_COLORS } from "../theme/theme";

const PriceModule = ({ refs, onClickContactUs }) => {
  return (
    <FullPage refs={refs}>
      <Stack width={"100%"} paddingTop={3} alignItems={"flex-end"}>
        <Typography variant="h2">cena</Typography>
      </Stack>
      <Grid container pt={2} spacing={5}>
        <Grid item xs={12} md={6} mt={3}>
          <Stack spacing={2}>
            <Typography variant={"body1"} fontStyle={"bold"} fontSize={24}>
              Cena: od{" "}
              <Typography
                component={"span"}
                variant={"body1"}
                color={STUDNERM_COLORS.ORANGE}
                fontStyle={"bold"}
                fontSize={24}
              >
                80€
              </Typography>{" "}
              za bežný meter
            </Typography>
            <Typography variant={"body1"} fontStyle={"bold"}>
              <Typography
                component={"span"}
                variant={"body1"}
                color={STUDNERM_COLORS.ORANGE}
              >
                Konečná cena
              </Typography>{" "}
              sa navrhuje po obhliadke miesta, ktorá závisí hlavne od
              hydrogeologických podmienok a metódy vŕtania. V cene je zahrnutá
              preprava, vŕtanie, špeciálny filtračný obsyp a čistenie studne.
            </Typography>
            <Typography variant={"body1"} fontStyle={"bold"}>
              Po dohodnutí podmienok je cena za vrtné práce vždy dodržaná,
              nezavádzame zákazníkov ponukou najnižšej ceny a nemeníme ju
              zdôvodnením zložitých hydrogeologických pomerov alebo prepravou a
              pod. Hĺbku studne účtujeme takú, akú reálne využijete, teda takú
              hĺbku, do akej osadíme PVC (modré - s atestom na pitnú vodu) rúry,
              aj keby sme reálne vŕtali omnoho hlbšie. V prípade potreby
              zabezpečíme aj osadenie čerpacej techniky, poprípade napojenie
              vody do domu.
            </Typography>
            <Typography variant={"body1"} fontStyle={"bold"}>
              Obhliadku miesta poskytujeme{" "}
              <Typography
                component={"span"}
                color={STUDNERM_COLORS.BLUE}
                fontStyle={"bold"}
              >
                bezplatne.
              </Typography>
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} mt={3}>
          <Box
            sx={{
              backgroundColor: STUDNERM_COLORS.GRAY_BLOCK,
              borderRadius: 5,
            }}
          >
            <Stack spacing={2} p={6}>
              <TextWithCheckIcon
                text={"obhliadka (ZDARMA)"}
                color={STUDNERM_COLORS.BLUE}
              />
              <TextWithCheckIcon
                text={"služby prepravy"}
                color={STUDNERM_COLORS.BLUE}
              />
              <TextWithCheckIcon
                text={"vŕtanie"}
                color={STUDNERM_COLORS.BLUE}
              />
              <TextWithCheckIcon
                text={"filtračný obsyp"}
                color={STUDNERM_COLORS.BLUE}
              />
              <TextWithCheckIcon
                text={"čistenie studne"}
                color={STUDNERM_COLORS.BLUE}
              />
              <TextWithCheckIcon
                text={"osadenie čerpacej techniky"}
                color={STUDNERM_COLORS.GRAY_TEXT}
              />
              <TextWithCheckIcon
                text={"pripojenie vody k domu"}
                color={STUDNERM_COLORS.GRAY_TEXT}
              />
              <Box width={"100%"} justifyContent={"center"} display={"flex"}>
                <Button variant={"orange"} onClick={onClickContactUs}>
                  kontaktujte nás!
                </Button>
              </Box>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </FullPage>
  );
};
export default PriceModule;
