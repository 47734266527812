import { Grid, Stack, Typography } from "@mui/material";
import { FullPage, TextWithHeader } from "../../components";
import zVrtanejStudne from "../../assets/zVrtanejStudne.png";

const WhyOwnWellModule = ({ refs }) => {
  return (
    <FullPage refs={refs}>
      <Stack width={"100%"}>
        <Typography variant="h2" sx={{ fontSize: { xs: 35, md: 46 } }}>
          Prečo mať vlastnú studňu?
        </Typography>
      </Stack>
      <Grid container pt={2} spacing={5}>
        <Grid item xs={12} md={6} mt={3}>
          <Stack spacing={5}>
            <TextWithHeader
              left={true}
              header="vlastný zdroj vody"
              text='Klimatológovia predpokladajú, že globálne otepľovanie zhorší podmienky pre zavlažovanie a využívanie vody na pitie. Hodnota vody neustále rastie a podobne ako sa ropa nazýva čierne zlato, voda získava čoraz viac prívlastok "biele zlato." Preto je mať vlastnú studnu výnimočnou príležitosťou na zabezpečenie stabilného a nezávislého zdroja vody.'
            />
            <TextWithHeader
              left={true}
              header="nezávislosť"
              text="Nie je neobvyklé, že mestské a obecné úrady počas sucha obmedzujú zavlažovanie záhrad a trávnikov z verejného vodovodu. Investovaním do vlastnej studne si zabezpečíte prístup k vode priamo z vášho pozemku, čím minimalizujete závislosť na verejných zdrojoch a chránite sa pred rastúcimi cenami vody."
            />
            <TextWithHeader
              left={true}
              header="šetrnosť financií aj životného prostredia"
              text="Nezávislosť vodného zdroja nie je len o úspore a pocite nezávislosti, ale hlavne o životnej nevyhnutnosti. Vlastná studňa šetrí financie aj životné prostredie, čo z nej robí investíciu do vašej budúcnosti."
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} display={"flex"} justifyContent={"center"}>
          <div style={{ maxHeight: 500 }}>
            <img
              src={zVrtanejStudne}
              alt="Fotka z vŕtanej studne"
              style={{
                maxWidth: "100%",
                maxHeight: 500,
                borderRadius: 30,
              }}
            />
          </div>
        </Grid>
      </Grid>
    </FullPage>
  );
};
export default WhyOwnWellModule;
