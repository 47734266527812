import React from 'react';
import ReactDOM from 'react-dom/client';
import {ThemeProvider} from "@mui/material";
import studnerm_theme from "./studnerm/theme/theme";
import MainPage from "./studnerm/MainPage";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={studnerm_theme}>
      <MainPage/>
    </ThemeProvider>
  </React.StrictMode>
);

