import { Box, Stack, Typography } from "@mui/material";
import { STUDNERM_COLORS } from "../studnerm/theme/theme";

const ProductBlock = ({ title, text, image }) => {
  return (
    <Box
      sx={{
        borderRadius: "30px",
        backgroundColor: STUDNERM_COLORS.GRAY_BLOCK,
        width: "400px",
        height: "250px",
      }}
    >
      <Stack
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        height={"100%"}
        width={"100%"}
        spacing={2}
      >
        <img
          src={image}
          alt="product"
          style={{ borderRadius: "50%", width: "100px", height: "100px" }}
        />
        <Typography variant="h3" textTransform={"uppercase"}>
          {title}
        </Typography>
        <Typography variant="body1">{text}</Typography>
      </Stack>
    </Box>
  );
};
export default ProductBlock;
