import { Grid, Stack, Typography } from "@mui/material";
import { FullPage, TextWithHeader } from "../../components";
import postupVrtania from "../../assets/postupVrtania.png";

const ProcessModule = ({ refs }) => {
  return (
    <FullPage refs={refs}>
      <Stack width={"100%"} paddingTop={3}>
        <Typography variant="h2">postup</Typography>
      </Stack>
      <Grid container pt={2} spacing={5}>
        <Grid item xs={12} md={6} display={"flex"} justifyContent={"center"}>
          <div style={{ maxHeight: 450 }}>
            <img
              src={postupVrtania}
              alt="Fotka z vŕtanej studne"
              style={{
                maxWidth: "100%",
                maxHeight: 450,
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6} mt={3}>
          <Stack spacing={5}>
            <TextWithHeader
              left={false}
              header="účel studne"
              text="Po kontakte prostredníctvom uvedeného kontaktného formulára, e-mailom alebo telefonicky, vykonáme predbežný hydrogeologický prieskum (prípadne vám odporučíme kvalifikovaného hydrogeológa) a následne zrealizujeme bezplatnú obhliadku miesta. Vypracujeme technické riešenie, vysvetlíme všetky možnosti a vypracujeme individuálnu cenovú ponuku, s dohodnutým termínom realizácie studne."
            />
            <TextWithHeader
              left={false}
              header="proces vŕtania"
              text="Pre optimálne vŕtanie potrebujeme prístupovú cestu širokú minimálne 180 cm a od plota či iných prekážok potrebujeme manipulačný priestor minimálne 200 cm. Dĺžka realizácie závisí od podložia, metódy vŕtania a hĺbky studne, zvyčajne trvá jeden až tri dni ale môže sa predĺžiť v závislosti od podložia."
            />
          </Stack>
        </Grid>
      </Grid>
    </FullPage>
  );
};
export default ProcessModule;
