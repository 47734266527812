import { Box, Typography } from "@mui/material";
import studnaNaDosah from "../../assets/StudnaNaDosah.png";
import { STUDNERM_COLORS } from "../theme/theme";

const OwnWellOnReachModule = ({ refs }) => {
  return (
    <Box
      paddingY={10}
      sx={{
        position: "relative",
        width: "100%",
        overflow: "hidden",
        maxHeight: "610px",
      }}
      ref={refs}
    >
      <Box
        component="img"
        src={studnaNaDosah}
        alt="Moment z vŕtanej studne"
        sx={{
          width: "100%",
          height: "610px",
          objectFit: "cover",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          padding: 1,
          //backgroundColor: "rgba(0, 0, 0, 0.5)",
          borderRadius: "20px",
        }}
      >
        <Typography
          variant="h2"
          sx={{ fontSize: { xs: 70, md: 80 } }}
          color={STUDNERM_COLORS.WHITE}
        >
          vlastná studňa na dosah!
        </Typography>
      </Box>
    </Box>
  );
};

export default OwnWellOnReachModule;
