import { createTheme } from "@mui/material";
import "@fontsource/dm-sans"; // Import the font
import "@fontsource/syne"; // Import the font

export const STUDNERM_COLORS = {
  GRAY_TEXT: "#827A72",
  GRAY_BLOCK: "#F2F2F2",
  BLACK: "#000000",
  WHITE: "#FFFFFF",
  ORANGE: "#F88F34",
  BLUE: "#5FB6E6",
};

const studnerm_theme = createTheme({
  palette: {
    primary: {
      main: STUDNERM_COLORS.WHITE,
    },
  },
  typography: {
    fontFamily: "DM Sans, sans-serif",
    h2: {
      fontFamily: "Syne, sans-serif",
      fontSize: 48,
      fontWeight: 700,
    },
    h3: {
      fontSize: 20,
      fontWeight: 700,
    },
    h4: {
      fontSize: 18,
      fontWeight: 500,
    },
    body1: {
      fontSize: 16,
      fontWeight: 700,
      color: STUDNERM_COLORS.GRAY_TEXT,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        style: {
          textTransform: "none",
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            borderRadius: "8px",
            height: "60px",
            backgroundColor: STUDNERM_COLORS.BLUE,
            color: STUDNERM_COLORS.WHITE,
            fontSize: 18,
            fontWeight: 500,
            "&:hover": {
              backgroundColor: "#8ebce3",
              color: STUDNERM_COLORS.WHITE,
            },
          },
        },
        {
          props: { variant: "orange" },
          style: {
            borderRadius: "8px",
            height: "55px",
            minWidth: "177px",
            backgroundColor: STUDNERM_COLORS.ORANGE,
            color: STUDNERM_COLORS.WHITE,
            fontSize: 18,
            fontWeight: 500,
            "&:hover": {
              backgroundColor: "#f5a874",
              color: STUDNERM_COLORS.WHITE,
            },
          },
        },
      ]
    },
  }
});

export default studnerm_theme;
