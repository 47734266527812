import Header from "./Header/Header";
import {
  ContactFormModule,
  GalleryModule,
  HeroModule,
  OwnWellOnReachModule,
  PriceModule,
  ProcessModule,
  ServicesModule,
  WhyOwnWellModule,
} from "./Modules";
import Footer from "./Footer/Footer";
import { useRef } from "react";

const MainPage = () => {
  const refHeroModule = useRef(null);
  const refWhyOnwWellModule = useRef(null);
  const refServicesModule = useRef(null);
  const refProcessModule = useRef(null);
  const refPriceModule = useRef(null);
  const refContactFormModule = useRef(null);
  const refGaleryModule = useRef(null);

  const scrollTo = (ref) => {
    window.scrollTo({
      behavior: "smooth",
      top: ref.current.offsetTop - 95,
    });
  };

  //todo prerobiť na array
  const onClictToScroll = {
    heroModule: () => scrollTo(refHeroModule),
    modules: [
      () => scrollTo(refWhyOnwWellModule),
      () => scrollTo(refServicesModule),
      () => scrollTo(refProcessModule),
      () => scrollTo(refPriceModule),
      () => scrollTo(refContactFormModule),
      () => scrollTo(refGaleryModule),
    ],
  };

  return (
    <>
      <Header headerOnClick={onClictToScroll} />
      <div
        style={{
          marginTop: 95,
        }}
      >
        <HeroModule
          refs={refHeroModule}
          onClickMoreInformation={() => scrollTo(refWhyOnwWellModule)}
        />
        <WhyOwnWellModule refs={refWhyOnwWellModule} />
        <OwnWellOnReachModule />
        <ServicesModule refs={refServicesModule} />
        <ProcessModule refs={refProcessModule} />
        <PriceModule
          refs={refPriceModule}
          onClickContactUs={() => scrollTo(refContactFormModule)}
        />
        <ContactFormModule refs={refContactFormModule} />
        <GalleryModule refs={refGaleryModule} />
        <Footer />
      </div>
    </>
  );
};

export default MainPage;
