import { Box, Grid, Stack, Typography } from "@mui/material";
import { STUDNERM_COLORS } from "../theme/theme";

const Footer = () => {
  const fimrUdaje = (velke) => {
    return (
      <Grid
        sx={{
          display: { xs: velke ? "none" : "grid", md: velke ? "grid" : "none" },
          marginBottom: { xs: 1, md: 0 },
        }}
        item
        xs={12}
        md={4}
      >
        <Typography
          variant="body1"
          color={STUDNERM_COLORS.WHITE}
          whiteSpace={"pre-line"}
          textAlign={velke ? "left" : "center"}
        >
          {"Studne RM\n" +
            "IČO: 40 518 698\n" +
            "DIČ: 10 47 50 48 10\n" +
            "Trenčianska cesta 19\n" +
            "Bánovce nad Bebravou\n" +
            "957 01"}
        </Typography>
      </Grid>
    );
  };

  const adresa = () => {
    return (
      <Grid item xs={12} md={4} sx={{ display: { xs: "none", md: "grid" } }}>
        <Typography
          variant="body1"
          color={STUDNERM_COLORS.WHITE}
          whiteSpace={"pre-line"}
          textAlign={"center"}
        >
          {"Trenčianska cesta 19\n Bánovce nad Bebravou\n 957 01"}
        </Typography>
      </Grid>
    );
  };

  const contactData = (velke) => {
    return (
      <Grid
        sx={{
          display: { xs: velke ? "none" : "grid", md: velke ? "grid" : "none" },
        }}
        item
        xs={12}
        md={4}
      >
        <Stack>
          <Typography
            variant={"body1"}
            color={STUDNERM_COLORS.WHITE}
            textAlign={velke ? "right" : "center"}
          >
            email:{" "}
            <Typography
              component={"a"}
              href={"mailto:voda@studnerm.sk"}
              color={STUDNERM_COLORS.WHITE}
            >
              voda@studnerm.sk
            </Typography>
          </Typography>
          <Typography
            variant={"body1"}
            color={STUDNERM_COLORS.WHITE}
            textAlign={velke ? "right" : "center"}
          >
            Tel.:{" "}
            <Typography
              component={"a"}
              href={"tel:0903223203"}
              color={STUDNERM_COLORS.WHITE}
            >
              0903 223 203{" "}
            </Typography>
          </Typography>
        </Stack>
      </Grid>
    );
  };

  return (
    <Box sx={{ width: "100%", backgroundColor: STUDNERM_COLORS.GRAY_TEXT }}>
      <Grid container sx={{ paddingX: 8, paddingY: 5 }}>
        {fimrUdaje(false)}
        {fimrUdaje(true)}
        {adresa()}
        {contactData(false)}
        {contactData(true)}
        <Grid item xs={12} marginTop={1}>
          <Typography
            variant={"body1"}
            color={STUDNERM_COLORS.WHITE}
            textAlign={"center"}
          >
            © 2024 StudneRM. All Rights Reserved.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
