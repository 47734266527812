import { FullPage } from "../../components";
import { Box, Stack, Typography } from "@mui/material";
import { MasonryPhotoAlbum } from "react-photo-album";
import "react-photo-album/masonry.css";
import { useEffect, useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

// Function to dynamically import images
const importAll = (r) => r.keys().map(r);

// Import all images from the Gallery folder
const images = importAll(
  require.context("../../assets/Gallery", false, /\.(jpg|jpeg|png)$/)
);

const getImageDimensions = (src) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.src = src;
  });
};

const GalleryModule = ({refs}) => {
  const [imagesArray, setImagesArray] = useState([]);
  const [index, setIndex] = useState(-1);

  useEffect(() => {
    const loadImages = async () => {
      const imagesWithDimensions = await Promise.all(
        images.map(async (src, index) => {
          const { width, height } = await getImageDimensions(src);
          return {
            src,
            width,
            height,
            // alt: `Obrázok ${index + 1}' z vrátania stunde`,
            // caption: `Ukážka z vŕtania studne`,
          };
        })
      );
      setImagesArray(imagesWithDimensions);
    };

    loadImages();
  }, []);

  // https://github.com/igordanchenko/react-photo-album?tab=readme-ov-file
  // https://react-photo-album.com/documentation
  // https://react-photo-album.com/#Layouts_Masonry
  // https://react-photo-album.com/examples/lightbox
  // https://codesandbox.io/p/devbox/elastic-wu-82djq9?file=%2Fsrc%2FApp.tsx%3A23%2C39-23%2C60
  return (
    <FullPage refs={refs}>
      <Stack spacing={5}>
        <Typography variant={"h2"} width={"100%"} textAlign={"center"}>
          galéria
        </Typography>
        <Box sx={{ maxWidth: "1500px", paddingBottom: 8 }} >
          <MasonryPhotoAlbum
            photos={imagesArray}
            targetRowHeight={150}
            onClick={({ index }) => setIndex(index)}
            columns={(containerWidth) => {
              if (containerWidth < 500) return 1;
              else if (containerWidth < 700) return 2;
              else if (containerWidth < 900) return 3;
              else if (containerWidth < 1100) return 3;
              else if (containerWidth < 1300) return 4;
              else return 5;
            }}
          />
          <Lightbox
            slides={imagesArray}
            open={index >= 0}
            index={index}
            close={() => setIndex(-1)}
            // enable optional lightbox plugins
            plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
          />
        </Box>
      </Stack>
    </FullPage>
  );
};

export default GalleryModule;
