import { Box, Grid, Stack, Typography } from "@mui/material";
import contectFormBackGround from "../../assets/contactFormularBackground.jpg";
import { STUDNERM_COLORS } from "../theme/theme";
import { useEffect, useRef, useState } from "react";

const ContactFormModule = ({ refs }) => {
  let container = useRef(null);
  let [height, setHeight] = useState(610);
  useEffect(() => {
    setHeight(
      container.current.offsetHeight > 610
        ? container.current.offsetHeight + 47
        : 610
    );
  }, [height]);
  // const [formData, setFormData] = useState({
  //   name: "",
  //   email: "",
  //   phone: "",
  //   additionalInfo: "",
  // });
  // const [recaptchaVerified, setRecaptchaVerified] = useState(false);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prev) => ({ ...prev, [name]: value }));
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   console.log(formData);
  //   if (!recaptchaVerified) {
  //     alert("Please verify the reCAPTCHA.");
  //     return;
  //   }
  //
  //   /*
  //   const email = process.env.REACT_APP_EMAIL;
  //   const response = await fetch('/api/send-email', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({ ...formData, email }),
  //   });
  //
  //   if (response.ok) {
  //     alert('Form submitted successfully!');
  //   } else {
  //     alert('Failed to submit the form.');
  //   }
  //
  //    */
  // };

  // const handleRecaptchaChange = (value) => {
  //   setRecaptchaVerified(!!value);
  // };

  return (
    <Box
      paddingY={10}
      sx={{
        position: "relative",
        width: "100%",
        overflow: "hidden",
        maxHeight: { height },
      }}
      ref={refs}
    >
      <Box
        component="img"
        src={contectFormBackGround}
        alt="Voda z točky"
        sx={{
          width: "100%",
          height: { height },
          objectFit: "cover",
        }}
      />
      <Grid
        container
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          padding: 1,
          //backgroundColor: "rgba(0, 0, 0, 0.5)",
          borderRadius: "20px",
          width: "85%",
        }}
        spacing={5}
        ref={container}
      >
        {/*<Grid item xs={12} md={8}> // todo na jeseň sa to dokončí alebo keď bude viacej času */}
        {/*  <Typography variant="h2" color={STUDNERM_COLORS.WHITE}>*/}
        {/*    kontakt{height}*/}
        {/*  </Typography>*/}
        {/*  <form onSubmit={handleSubmit}>*/}
        {/*    <TextField*/}
        {/*      fullWidth*/}
        {/*      required*/}
        {/*      name="name"*/}
        {/*      label="Meno a Priezvisko"*/}
        {/*      value={formData.name}*/}
        {/*      onChange={handleChange}*/}
        {/*      margin="normal"*/}
        {/*      InputLabelProps={{*/}
        {/*        style: { color: STUDNERM_COLORS.WHITE },*/}
        {/*      }}*/}
        {/*      InputProps={{*/}
        {/*        style: { color: STUDNERM_COLORS.WHITE },*/}
        {/*        sx: {*/}
        {/*          "& .MuiOutlinedInput-root": {*/}
        {/*            "& fieldset": {*/}
        {/*              borderColor: STUDNERM_COLORS.WHITE,*/}
        {/*            },*/}
        {/*            "&:hover fieldset": {*/}
        {/*              borderColor: STUDNERM_COLORS.WHITE,*/}
        {/*            },*/}
        {/*            "&.Mui-focused fieldset": {*/}
        {/*              borderColor: STUDNERM_COLORS.WHITE,*/}
        {/*            },*/}
        {/*          },*/}
        {/*        },*/}
        {/*      }}*/}
        {/*    />*/}
        {/*    <TextField*/}
        {/*      fullWidth*/}
        {/*      required*/}
        {/*      name="email"*/}
        {/*      label="Emailová adresa"*/}
        {/*      value={formData.email}*/}
        {/*      onChange={handleChange}*/}
        {/*      margin="normal"*/}
        {/*      InputLabelProps={{*/}
        {/*        style: { color: STUDNERM_COLORS.WHITE },*/}
        {/*      }}*/}
        {/*      InputProps={{*/}
        {/*        style: { color: STUDNERM_COLORS.WHITE },*/}
        {/*        sx: {*/}
        {/*          "& .MuiOutlinedInput-root": {*/}
        {/*            "& fieldset": {*/}
        {/*              borderColor: STUDNERM_COLORS.WHITE,*/}
        {/*            },*/}
        {/*            "&:hover fieldset": {*/}
        {/*              borderColor: STUDNERM_COLORS.WHITE,*/}
        {/*            },*/}
        {/*            "&.Mui-focused fieldset": {*/}
        {/*              borderColor: STUDNERM_COLORS.WHITE,*/}
        {/*            },*/}
        {/*          },*/}
        {/*        },*/}
        {/*      }}*/}
        {/*    />*/}
        {/*    <TextField*/}
        {/*      fullWidth*/}
        {/*      required*/}
        {/*      name="phone"*/}
        {/*      label="Telefónne čislo"*/}
        {/*      value={formData.phone}*/}
        {/*      onChange={handleChange}*/}
        {/*      margin="normal"*/}
        {/*      InputLabelProps={{*/}
        {/*        style: { color: STUDNERM_COLORS.WHITE },*/}
        {/*      }}*/}
        {/*      InputProps={{*/}
        {/*        style: { color: STUDNERM_COLORS.WHITE },*/}
        {/*        sx: {*/}
        {/*          "& .MuiOutlinedInput-root": {*/}
        {/*            "& fieldset": {*/}
        {/*              borderColor: STUDNERM_COLORS.WHITE,*/}
        {/*            },*/}
        {/*            "&:hover fieldset": {*/}
        {/*              borderColor: STUDNERM_COLORS.WHITE,*/}
        {/*            },*/}
        {/*            "&.Mui-focused fieldset": {*/}
        {/*              borderColor: STUDNERM_COLORS.WHITE,*/}
        {/*            },*/}
        {/*          },*/}
        {/*        },*/}
        {/*      }}*/}
        {/*    />*/}
        {/*    <TextField*/}
        {/*      fullWidth*/}
        {/*      required*/}
        {/*      name="additionalInfo"*/}
        {/*      label="Popis"*/}
        {/*      value={formData.additionalInfo}*/}
        {/*      onChange={handleChange}*/}
        {/*      margin="normal"*/}
        {/*      multiline*/}
        {/*      rows={4}*/}
        {/*      InputLabelProps={{*/}
        {/*        style: { color: STUDNERM_COLORS.WHITE },*/}
        {/*      }}*/}
        {/*      InputProps={{*/}
        {/*        style: { color: STUDNERM_COLORS.WHITE },*/}
        {/*        sx: {*/}
        {/*          "& .MuiOutlinedInput-root": {*/}
        {/*            "& fieldset": {*/}
        {/*              borderColor: STUDNERM_COLORS.WHITE,*/}
        {/*            },*/}
        {/*            "&:hover fieldset": {*/}
        {/*              borderColor: STUDNERM_COLORS.WHITE,*/}
        {/*            },*/}
        {/*            "&.Mui-focused fieldset": {*/}
        {/*              borderColor: STUDNERM_COLORS.WHITE,*/}
        {/*            },*/}
        {/*          },*/}
        {/*        },*/}
        {/*      }}*/}
        {/*    />*/}
        {/*    <Box display="flex" justifyContent="center" margin="normal">*/}
        {/*      <ReCAPTCHA*/}
        {/*        sitekey={process.env.REACT_APP_RECAPCHA_KEY}*/}
        {/*        onChange={handleRecaptchaChange}*/}
        {/*      />*/}
        {/*    </Box>*/}
        {/*    <Box display="flex" justifyContent="center" margin="normal">*/}
        {/*      <Button variant="contained" color="primary" type="submit">*/}
        {/*        Send*/}
        {/*      </Button>*/}
        {/*    </Box>*/}
        {/*  </form>*/}
        {/*</Grid>*/}

        {/*<Grid item xs={12} md={4}>*/}
        <Grid item xs={12} md={12}>
          <Box
            sx={{
              backgroundColor: "rgba(242,242,242,0.69)",
              borderRadius: "20px",
            }}
          >
            <Stack spacing={2} p={6}>
              <Typography
                variant={"body1"}
                color={STUDNERM_COLORS.BLACK}
                textAlign={"left"}
              >
                email:{" "}
                <Typography
                  component={"a"}
                  href={"mailto:voda@studnerm.sk"}
                  color={STUDNERM_COLORS.BLACK}
                >
                  voda@studnerm.sk
                </Typography>
              </Typography>
              <Typography
                variant={"body1"}
                color={STUDNERM_COLORS.BLACK}
                textAlign={"left"}
              >
                Tel.:{" "}
                <Typography
                  component={"a"}
                  href={"tel:0903223203"}
                  color={STUDNERM_COLORS.BLACK}
                >
                  0903 223 203{" "}
                </Typography>
                <Typography component={"span"} variant={"body1"}></Typography>
              </Typography>
              <Typography variant={"body1"} textAlign={"left"}>
                Preferujeme okres Bánovce n. Bebravou. Kopané (skružové) studne
                neprehlbujeme ani nečistíme.
              </Typography>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactFormModule;
