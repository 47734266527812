import { FullPage, ProductBlock } from "../../components";
import { Grid, Typography } from "@mui/material";
import ZVrtaniaStudne from "../../assets/zVrtanejStudne.png";
import cistenie from "../../assets/cistenie.png";
import zapojenie from "../../assets/zapojenie.jpeg";
import skuska from "../../assets/skuska.jpeg";

const ServicesModule = ({ refs }) => {
  return (
    <FullPage refs={refs}>
      <Typography variant="h2">služby</Typography>
      <Grid container spacing={5} maxWidth={900} pt={8}>
        <Grid
          item
          xs={12}
          md={6}
          width={450}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <ProductBlock
            title="Vŕtanie studní"
            text="vŕtanie studní na kľúč"
            image={ZVrtaniaStudne}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          width={450}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <ProductBlock
            title="ODKALENIE"
            text="prečistenie navŕtanej vody"
            image={cistenie}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <ProductBlock
            title="VYSTROJENIE STUDNE"
            text="efektívne využitie studne"
            image={zapojenie}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <ProductBlock
            title="ČERPACIA SKÚŠKA"
            text="skúška kapacity studne"
            image={skuska}
          />
        </Grid>
      </Grid>
    </FullPage>
  );
};

export default ServicesModule;