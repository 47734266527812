import { Grid, Typography } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import React, { useState } from "react";
import WaterDropOutlinedIcon from "@mui/icons-material/WaterDropOutlined";

const TextWithHeader = ({ header, text, left }) => {
  const [rowCount, setRowCont] = useState(2);
  const handleOnClic = () => {
    setRowCont(rowCount > 0 ? 0 : 2);
  };
  return (
    <div>
      <Grid container flexDirection={left ? "row" : "row-reverse"}>
        <Grid item xs={1} display={"flex"} justifyContent={"center"}>
          <WaterDropOutlinedIcon />
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h3" textAlign={left === true ? "left" : "right"}>
            {header}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <ArrowForwardIos
            onClick={handleOnClic}
            style={{
              transform: rowCount > 0 ? "rotate(0deg)" : "rotate(90deg)",
            }}
          />
        </Grid>
      </Grid>
      <Typography
        variant="body1"
        mt={1.3}
        sx={{
          display: "-webkit-box",
          WebkitLineClamp: rowCount,
          overflow: "hidden",
          textOverflow: "ellipsis",
          WebkitBoxOrient: "vertical",
        }}
      >
        {text}
      </Typography>
    </div>
  );
};

export default TextWithHeader;