import { Box } from "@mui/material";

const FullPage = ({ refs, ...props }) => {
  return (
    <Box
      ref={refs}
      sx={{
        // todo ak by to bolo otravné tak dvh sa dá zmeniť na svh https://stackoverflow.com/a/72245072
        minHeight: "calc(100dvh - 95px)", // Full screen height minus the header height
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        paddingX: 8,
      }}
    >
      {props.children}
    </Box>
  );
};
export default FullPage;
