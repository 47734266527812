import React, { useState } from "react";
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../assets/logo.png";
import { STUDNERM_COLORS } from "../theme/theme";
import WaterDropOutlinedIcon from "@mui/icons-material/WaterDropOutlined"; // Adjust the path to your logo

const Header = ({ headerOnClick }) => {
  const pages = [
    "vlastná studňa",
    "služby",
    "postup",
    "cena",
    "kontakt",
    "galéria",
  ];
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOnClickMenu = (index) => {
    headerOnClick.modules[index]();
    setAnchorElNav(null);
  };

  const menu = (menuItem) => (
    <Typography
      variant="h4"
      color={
        menuItem === "kontakt" ? STUDNERM_COLORS.ORANGE : STUDNERM_COLORS.BLACK
      }
      textAlign="center"
    >
      {menuItem}
    </Typography>
  );

  const getMenu = () => {
    return pages.map((page, index) => (
      <MenuItem key={page} onClick={() => handleOnClickMenu(index)}>
        {menu(page)}
      </MenuItem>
    ));
  };

  return (
    <AppBar
      position="fixed"
      style={{
        height: 95,
        display: "flex",
        alignItems: "center",
        boxShadow: "none",
        backgroundColor: STUDNERM_COLORS.WHITE,
      }}
    >
      <Container maxWidth="md" style={{ height: "100%" }}>
        <Toolbar disableGutters style={{ height: "100%" }}>
          {/* Logo */}
          <Box
            component="img"
            src={logo}
            alt="logo"
            sx={{ width: 42, height: 45.11, mr: 2 }}
            onClick={headerOnClick.heroModule}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: { xs: "center", md: "flex-end" },
              alignItems: "center",
            }}
            onClick={headerOnClick.heroModule}
          >
            <WaterDropOutlinedIcon />
          </Box>

          {/* Desktop Menu */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
            }}
          >
            {getMenu()}
          </Box>

          {/* Mobile Menu Icon */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {getMenu()}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
