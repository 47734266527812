import textLogo from "../../assets/nazov.png";
import heroImage from "../../assets/heroImage.JPG";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { FullPage } from "../../components";

const HeroModule = ({ refs, onClickMoreInformation }) => {
  return (
    <FullPage refs={refs}>
      <Grid container width={"100vw"}>
        <Grid item xs={12} md={5}>
          <Box
            component={"img"}
            src={heroImage}
            alt="Centered Logo"
            sx={{
              width: "100%",
              height: { md: "calc(100dvh - 95px)", xs: "calc(30dvh - 30px)" },
              objectFit: "cover",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          padding={5}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={textLogo}
            alt="Centered Logo"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
          <Typography
            fontSize={24}
            fontWeight={500}
            paddingTop={5}
            sx={{
              textAlign: { xs: "left", md: "right" },
            }}
          >
            Venujeme sa vŕtaniu studní na zavlažovanie záhrad, trávnikov ale aj
            pitnej vody napojenej do vašej domácnosti, aby sme zabezpečili, že
            vaša domácnosť nezostane bez vody!
          </Typography>

          <Stack width={"100%"} direction={"row"}>
            <div>
              <Button
                variant="contained"
                sx={{ marginTop: 5 }}
                onClick={onClickMoreInformation}
              >
                zistite viac
              </Button>
            </div>
          </Stack>
        </Grid>
      </Grid>
    </FullPage>
  );
};

export default HeroModule;
