import CheckIcon from "@mui/icons-material/Check";
import { Stack, Typography } from "@mui/material";

const TextWithCheckIcon = ({ text, color }) => {
  return (
    <Stack direction={"row"} spacing={1}>
      <CheckIcon sx={{ color: color }} />
      <Typography variant={"h3"}>{text}</Typography>
    </Stack>
  );
};

export default TextWithCheckIcon;